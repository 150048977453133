<template>
    <div class="lay_container">
        <Header @setCouRefreshKey="getCouRefreshKey"></Header>
        <div class="cont_m ">
            <div class="max_cont_width flex_box">
                <div class="left_menu">
                    <ul class="de_menu_list">
                        <li class="de_menu_item" v-for="(item, index) in menuList" :key="index">
                            <span class="de_menu_item_a phoneconutry" :class="linkActive == index ? 'router-link-active' : ''" @click="handlFristMenu(item), (linkActive = index)">
                                <!-- :class="'ic_' + index" -->
                                <div class="title">
                                    {{ item.title }}
                                    <i class="el-icon-lock" style="font-weight: 800; position: absolute; left: 240px; top: 12px" v-if="!item.power && item.id != '9001'"></i>
                                </div>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="right_box phone-bottmo">
                    <div class="flex_center_between_box">
                        <div class="c_detail_top_left line_2">
                            <div class="c_d_title" v-show="!indexIsTranslate">
                                {{ obj.countryName }}
                            </div>
                            <div class="c_d_title" v-show="indexIsTranslate">
                                {{ obj.countryNameCn }}
                            </div>

                            <div class="flex_between_box">
                                <div v-if="window.innerWidth < 821" style="font-size: 18px; display: flex; align-items: center" class="">
                                    <i class="zhankai" style="margin-right: 10px; float: left" @click="applefilters"><img src="../../assets/img/guolv.png" alt="" /></i>
                               
                                </div>
                                <div class="last_date">Last Update:{{ obj.countryYear }}</div>
                            </div>
                        </div>
                        <div class="c_detail_top_right computer_right_cont_box">
                            <div class="c_d_buttom flex_box">
                                <!-- <el-popover placement="bottom" trigger="hover" width="240" popper-class="pop_link"> -->
                                <button class="btn_blue flex_center" slot="reference" @click="compareCompanyCountry('Country')">
                                    <img src="../../assets/img/detail/btn_ic1.png" />
                                    Compare
                                </button>
                                <!-- <ul class="compare_box">
                              
                                        <li class="link_disable">Company</li>
                                        <li >Country</li>
                                    </ul> -->
                                <!-- </el-popover> -->
                                <!-- <el-tooltip :content="toolPop" :visible-arrow="false" placement="top" effect="light">
                                    <button class="btn_gray" v-if="orderStatus == '4'">In Production...</button>
                                </el-tooltip> -->
                                <button class="btn_blue flex_center" disabled>
                                    <img src="../../assets/img/detail/btn_ic8.png" />
                                    <!-- <img v-show="!updateDisabled" src="../../assets/img/detail/btn_ic2.png" /> -->
                                    Update
                                </button>
                                <!-- <button class="btn_blue flex_center" @click="reporter()">
                                    <img src="../../assets/img/detail/btn_ic3.png" />
                                    Report Error
                                </button> -->
                            </div>
                            <div class="c_d_icbtn">
                                <el-tooltip class="item" popper-class="tool_tip" :visible-arrow="false" effect="light" content="Translate" placement="bottom">
                                    <el-button class="tool_blue" @click="btnTranslate">
                                        <img src="../../assets/img/detail/head_ic1.png" />
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip class="item" popper-class="tool_tip tool_tip2" effect="light" :visible-arrow="false" content="Download Online Report" placement="bottom">
                                    <download-excel style="float: right; margin-left: 20px" class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" :header="title" name="country.xls">
                                        <el-button class="tool_blue" @click="exportCompany">
                                            <img src="../../assets/img/detail/head_ic3.png" />
                                        </el-button>
                                    </download-excel>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="country_box">
                        <div class="title">
                            <div class="title_txt flex_center_start_box">
                                <img src="../../assets/img/detail/title_ic9.png" />
                                Country Data
                            </div>
                        </div>
                        <div class="color_box computer_right_cont_box">
                            <div class="color color1">Good</div>
                            <div class="color color2">Average</div>
                            <div class="color color3">Bad</div>
                        </div>
                        <ul class="coun_card">
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic1.png" />
                                    Population
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.population.value }}</span>
                                    <span>{{ obj.population.rank }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic2.png" />
                                    GDP
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.gdp.value }}</span>
                                    <span>{{ obj.gdp.rank }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic3.png" />
                                    Total Trade
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.totalTrade.value }}</span>
                                    <span>{{ obj.totalTrade.rank }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic4.png" />
                                    FDI Inflow
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.fdiInflow.value }}</span>
                                    <span>{{ obj.fdiInflow.rank }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic5.png" />
                                    Gov Debt/GDP
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.debtGDP.value }}</span>
                                    <span>{{ obj.debtGDP.rank }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color2"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic6.png" />
                                    Business Environment
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.busEnvironment.value }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic7.png" />
                                    Legal Environment
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.legalEnvironment.value }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color3"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic8.png" />
                                    Competitive Index
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.cIndex.value }}</span>
                                    <span>{{ obj.cIndex.rank }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic9.png" />
                                    Country Rating
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.cRating.value }}</span>
                                </div>
                            </li>
                            <li>
                                <div class="coun_card_color color1"></div>
                                <div class="coun_card_tit flex_center">
                                    <img src="../../assets/img/detail/country_ic9.png" />
                                    Total Confidence
                                </div>
                                <div class="coun_card_score">
                                    <span>{{ obj.cConfidence.value }}</span>
                                    <span>{{ obj.cConfidence.rank }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <router-view />
                </div>
            </div>
        </div>
        <Footer v-if="window.innerWidth > 821"></Footer>
        <!-- Phone_menu -->
        <el-drawer title="" :visible.sync="drawer" size="300px" :with-header="false" direction="ltr">
            <transition name="fade" :duration="500">
                <div class="phoneappl_filters">
                    <div class="left_menu">
                        <ul class="de_menu_list">
                            <li class="de_menu_item" v-for="(item, index) in menuList" :key="index">
                                <span class="de_menu_item_a" :class="linkActive == index ? 'router-link-active' : ''" @click="handlFristMenu(item), (linkActive = index)">
                                    <!-- :class="'ic_' + index" -->
                                    <div class="title">
                                        {{ item.title }}
                                        <i class="el-icon-lock" style="font-weight: 800; position: absolute; left: 240px; top: 12px" v-if="!item.power && item.id != '9001'"></i>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </transition>
        </el-drawer>
        <!-- 报错弹框 -->
        <Reporterror :showReport="showReportIndex" @closeReportDialog="closeDialog($event)"></Reporterror>
    </div>
</template>
<script>
import { getCountryData, getCompanyDetailMenuList, ordersAdd, searchCountryInfoWithExcel } from '@/api/companyApi.js';
import bus from '@/components/bus';
import crypto from '@/utils/crypto';
import Footer from '@/components/footer';
import Header from '@/components/header';
import Reporterror from '@/components/reportError';
import { mixins1 } from '@/mixins/index';
export default {
    components: { Header, Footer, Reporterror },

    mixins: [mixins1],
    data() {
        return {
            drawer: false,
            linkActive: -1,
            indexIsTranslate: false,
            menutitle: 'Country Data',
            menuTitleList: [{ title: 'Country Data' }, { title: 'Profile' }, { title: 'Economy' }, { title: 'Trade' }, { title: 'Business' }, { title: 'Ratings' }, { title: 'View History' }],
            obj: {
                busEnvironment: '',
                cIndex: { rank: '', value: '' },
                cRating: '',
                debtGD: { rank: '', value: '' },
                fdiInflow: { rank: '', value: '' },
                debtGDP: { rank: '', value: '' },
                gdp: { rank: '', value: '' },
                legalEnvironment: '',
                population: { rank: '', value: '' },
                totalTrade: { rank: '', value: '' },
                cConfidence: { rank: '', value: '' },
            },
            menuList: [],
            title: 'Country',
            DetailsForm: [],
            json_fields: {
                busEnvironment: 'busEnvironment',
                cConfidence: 'cConfidence',
            },
            searchName: 'Country Data',
            companyCountry: '',

            showReportIndex: false,
            //权限接口获取
            powerResult: [],
            powerCurStatus: {},
            token: localStorage.getItem('token'),
            refreshKey: '',
            window: window,
        };
    },
    computed: {},
    mounted() {
        window.addEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
            if (this.token) this.getPower();
        });
    },
    watch: {
        refreshKey: {
            handler: function (val, oldVal) {
                this.getPower();
            },
            immediate: true,
        },
    },
    // watch: {
    //     token: {
    //         handler(newVal, oldVal) {
    //             if (newVal) {
    //                 this.getPower();
    //                 console.log('11111111111111');
    //             }
    //         },
    //         immediate: true,
    //     },
    // },
    //页面销毁
    beforeDestroy() {
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });
    },
    destroyed() {
        bus.$off('handleShowLogin');
    },
    created() {
        if (!this.token) this.getPower();
    },
    methods: {
        //导出

        applefilters() {
            if (window.innerWidth < 821) {
                this.drawer = !this.drawer;
            }
        },
        exportCompany() {
            if (!this.token) {
                bus.$emit('handleShowLogin', true);
            } else {
                // console.log(`this.obj`, this.obj);
                let power = this.powerResult[7].power;
                let deductionPoint = this.powerResult[7].deductionPoint;
                let couty = [this.$route.query.companyCountry];
                if (deductionPoint) {
                    if (!power) {
                        this.getOrdersAdd().then(res => {
                            if (res) {
                                searchCountryInfoWithExcel({
                                    types: ['CT', 'CE', 'CP', 'CB', 'CR'],
                                    countryCodes: couty,
                                    // years :'[2020]'
                                }).then(res => {
                                    if (res) {
                                        // console.log(`res`, res);
                                        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
                                        const fileName = 'country.xlsx'; // 文件名
                                        const href = URL.createObjectURL(blob);
                                        const link = document.createElement('a');
                                        link.href = href;
                                        link.download = fileName; // 设置下载文件名
                                        link.click();
                                    }
                                    // 获取到二进制数据
                                });
                            }
                        });
                    } else {
                        searchCountryInfoWithExcel({
                            types: ['CT', 'CE', 'CP', 'CB', 'CR'],
                            countryCodes: couty,
                            // years :'[2020]'
                        }).then(res => {
                            if (res) {
                                const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
                                const fileName = 'country.xlsx'; // 文件名
                                const href = URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = href;
                                link.download = fileName; // 设置下载文件名
                                link.click();
                            }
                            // 获取到二进制数据
                        });
                    }
                } else {
                    this.message();
                }
            }

            // this.DetailsForm = this.checkedCompanyList;
        },
        //权限
        async getPower() {
            this.getMenuList().then(async () => {
                let hasPower = this.powerResult[0].power;
                // || (!hasPower && (await this.getOrdersAdd()))
                if (hasPower) {
                    this.getData();
                }
            });
        },
        getData() {
            // this.searchName = this.$route.query.searchname ? this.$route.query.searchname : ''
            this.companyCountry = this.$route.query.companyCountry;
            let params = 'companyCountry=' + this.companyCountry;

            getCountryData(params).then(result => {
                let data = JSON.parse(crypto.decrypt(result));
                if (data && data.code && data.data) {
                    this.obj = data.data;
                }
            });
        },

        //获取目录
        async getMenuList() {
            this.companyCountry = this.$route.query.companyCountry;
            const type = 9;
            let res = await getCompanyDetailMenuList('code=' + this.companyCountry + '&type=' + type);
            if (res && res.code) {
                this.powerResult = res.data;
                let list = res.data;
                var arrLsit = [];
                list.forEach((item, i) => {
                    if (item.type == '1') {
                        if (item.title == 'Country Data') {
                            let obj = {};
                            obj = {
                                id: item.code,
                                title: item.title,
                                login: item.login,
                                power: item.power,
                                list: item.list,
                                url: '/country',
                            };
                            if (this.$route.name === item.title.replace(/\s/g, '') && !item.power) {
                                this.refreshPowerStatus(item.title);
                            }

                            if (this.$route.name === item.title.replace(/\s/g, '')) this.linkActive = i;
                            arrLsit.push(obj);
                        } else {
                            let obj = {};
                            obj = {
                                id: item.code,
                                title: item.title,
                                login: item.login,
                                power: item.power,
                                list: item.list,
                                url: '/country/' + item.title.replace(/\s/g, '').toLowerCase(), //+ '?companyCountry=' + this.companyCountry,
                            };
                            if (this.$route.name === item.title.replace(/\s/g, '') && !item.power) {
                                this.refreshPowerStatus(item.title);
                            }
                            if (this.$route.name === item.title.replace(/\s/g, '')) this.linkActive = i;
                            arrLsit.push(obj);
                        }
                    } else {
                        this.powerCurStatus[item.title.replace(/\s/g, '')] = item.power;
                    }
                });
                this.menuList = arrLsit;
                // console.log('this.menuList-- ', this.menuList);
            }
        },
        //1.点击菜单
        handlFristMenu(obj) {
            console.log(obj);
            this.menutitle = obj.title;
            //1.判断是否登录 login: true 需要登录
            if (obj.login && !this.token) {
                bus.$emit('handleShowLogin', true);
            } else {
                //2.判断是否需要扣点 power: false 无权限，需要扣点
                if (!obj.power) {
                    this.message();
                    this.refreshPowerStatus(obj.title).then(isPower => {
                        if (isPower) this.$router.push({ path: obj.url, query: { companyCountry: this.companyCountry } });
                    });
                } else {
                    this.$router.push({ path: obj.url, query: { companyCountry: this.companyCountry } });
                }
            }
        },
        //选择title 扣点
        async refreshPowerStatus(title) {
            if (title === 'export') {
                let status = await this.getOrdersAdd();
                return status;
            }
        },
        /**
         *用户购买报告 "type": 15 ：CountryDetailExport
         */
        getOrdersAdd() {
            const type = 15;
            //1  :线上报告
            let params = {
                companyName: this.obj.countryName, // 国家名称
                aaaId: this.companyCountry, // 国家二位编号
                companyCountry: this.companyCountry, // 国家二位编码
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            return new Promise((resolve, reject) => {
                ordersAdd(params).then(result => {
                    let data = result;
                    if (data && data.code) {
                        this.getMenuList();
                        if (data.msg != 'Saved successfully') {
                            this.$message({
                                message: data.msg,
                                type: 'success',
                                // duration: 5000,
                            });
                        }
                        resolve(true);
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that = this;
                        let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

                        let routeData = '';
                        setTimeout(function () {
                            if (userinfo.type == '3') {
                                routeData = that.$router.resolve({
                                    path: '/account/quota',
                                });
                            } else {
                                routeData = that.$router.resolve({
                                    path: '/account/addCredit',
                                });
                            }

                            window.open(routeData.href, '_blank');
                        }, 2000);
                        resolve(false);
                    }
                });
            });
        },
        getCouRefreshKey(val) {
            this.refreshKey = val;
        },

        btnTranslate() {
            this.indexIsTranslate = !this.indexIsTranslate;
        },

        //关闭report弹窗
        closeDialog(value) {
            this.showReportIndex = value;
        },
        //    reporter
        reporter() {
            if (!this.token) {
                bus.$emit('handleShowLogin', true);
            } else {
                this.showReportIndex = true;
            }
        },
        //compare
        compareCompanyCountry(name) {
            if (!this.token) {
                bus.$emit('handleShowLogin', true);
            } else {
                if (this.powerCurStatus.Compare) {
                    // this.getOrdersAdd().then(res => {
                    // if (res) this.$router.push({ path: '/company/comparecountry', query: { companyCountry: this.companyCountry } });
                    //  if(res){
                    let routeData = this.$router.resolve({
                        path: '/company/comparecountry?companyCountry=' + this.companyCountry,
                    });

                    window.open(routeData.href, '_blank');
                    //  }

                    // });
                } else {
                    this.message();
                }
            }
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 170px;
    margin-bottom: 80px;
}
.country_box {
    margin-top: 60px;
}
.title {
    margin-bottom: 20px;
    position: relative;
}
.country_box .title {
    margin-bottom: 0;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.coun_card {
    display: flex;
    flex-flow: wrap;
    margin-top: 20px;
}
.coun_card li {
    width: 220px;
    height: 70px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    background-color: #fff;
    margin-right: 20px;
    margin-bottom: 30px;
}
.coun_card li:nth-child(5n) {
    margin-right: 0;
}
.coun_card_color {
    width: 100%;
    height: 8px;
    margin-bottom: 5px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.coun_card_score {
    margin-top: 14px;
    font-size: 14px;
    color: #022955;
    line-height: 16px;
    text-align: center;
}

.coun_card_score span {
    font-family: 'Arial Bold';
}
.coun_card_score span + span {
    margin-left: 20px;
}
.coun_card_tit {
    font-size: 14px;
    color: #8497ab;
    line-height: 16px;
    text-align: center;
}
.coun_card_tit img {
    height: 20px;
    margin-right: 6px;
}
.color_box .color1::before,
.coun_card_color.color1 {
    background-color: #55b419;
}
.color_box .color2::before,
.coun_card_color.color2 {
    background-color: #ffd100;
}
.color_box .color3::before,
.coun_card_color.color3 {
    background-color: #f56b6b;
}
.color_box {
    display: flex;
    justify-content: flex-end;
    margin-top: -18px;
}
.color_box .color {
    position: relative;
    padding-left: 30px;
    margin-left: 10px;
}
.color_box .color::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    height: 10px;
    border-radius: 2px;
}
/* 菜单 */
.left_menu {
    align-self: flex-start;
    padding: 20px 20px 20px 10px;
}
/* .de_menu_item:not(:last-child) {
  margin-bottom: 10px;
} */
.de_menu_item_a {
    display: inline-block;
    color: #8497ab;
}
.de_menu_item_a {
    font-size: 16px;
}
.de_menu_item_a .title {
    padding: 10px 10px 10px 10px;
    background-size: 20px 20px;
    background-position: 10px center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.de_menu_item_a.router-link-active .title {
    background-color: #ebf2ff;
    border-radius: 14px;
    color: #022955;
    font-family: 'Arial Bold';
}
.de_menu_item_a .title.ic_0 {
    background-image: url(../../assets/img/detail/menu_ic10-1.png);
}
.de_menu_item_a.router-link-active .title.ic_0 {
    background-image: url(../../assets/img/detail/menu_ic10-2.png);
}
.de_menu_item_a .title.ic_1 {
    background-image: url(../../assets/img/detail/menu_ic11-1.png);
}
.de_menu_item_a.router-link-active .title.ic_1 {
    background-image: url(../../assets/img/detail/menu_ic11-2.png);
}
.de_menu_item_a .title.ic_2 {
    background-image: url(../../assets/img/detail/menu_ic12-1.png);
}
.de_menu_item_a.router-link-active .title.ic_2 {
    background-image: url(../../assets/img/detail/menu_ic12-2.png);
}
.de_menu_item_a .title.ic_3 {
    background-image: url(../../assets/img/detail/menu_ic13-1.png);
}
.de_menu_item_a.router-link-active .title.ic_3 {
    background-image: url(../../assets/img/detail/menu_ic13-2.png);
}
.de_menu_item_a .title.ic_4 {
    background-image: url(../../assets/img/detail/menu_ic14-1.png);
}
.de_menu_item_a.router-link-active .title.ic_4 {
    background-image: url(../../assets/img/detail/menu_ic14-2.png);
}
.de_menu_item_a .title.ic_5 {
    background-image: url(../../assets/img/detail/menu_ic15-1.png);
}
.de_menu_item_a.router-link-active .title.ic_5 {
    background-image: url(../../assets/img/detail/menu_ic15-2.png);
}
.de_menu_item_a .title.ic_6 {
    background-image: url(../../assets/img/detail/menu_ic16-1.png);
}
.de_menu_item_a.router-link-active .title.ic_6 {
    background-image: url(../../assets/img/detail/menu_ic16-2.png);
}

/* 头部 */
.flex_between_box {
    display: flex;
    justify-content: space-between;
}
.c_detail_top_right,
.c_detail_top_left {
    margin-top: 30px;
}
.c_d_title {
    width: 490px;
    height: 74px;
    font-size: 28px;
    font-family: 'Arial Bold';
    line-height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 20px;
}
.c_d_tag {
    width: 400px;
}
.c_d_tag .el-tag {
    height: 26px;
    line-height: 26px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ff7600;
    font-size: 12px;
    color: #ff7600;
    margin: 0 4px 6px;
}
.last_date {
    line-height: 27px;
    font-size: 12px;
    color: #8497ab;
}
.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}
.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}

.c_d_buttom {
    margin-bottom: 40px;
}
.c_d_buttom > span + button,
.c_d_buttom button + button {
    margin-left: 10px;
}
.c_d_icbtn {
    text-align: right;
}
.c_d_icbtn .el-button {
    width: 26px;
    height: 26px;
    background: #022955;
    border-radius: 13px;
    border: none;
    padding: 0;
}
.c_d_icbtn .el-button img {
    width: 14px;
}
.el-button.down_btn,
.btn_blue:disabled {
    color: #8497ab;
    background: #e7eaed;
    cursor: no-drop;
}
.tool_add {
    background: #ff7600 !important;
}
.tool_tip {
    height: 34px;
    font-size: 14px;
    color: #022955;
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(10, 33, 57, 0.2);
    border-radius: 8px;
    border: none !important;
}
.el-tooltip__popper[x-placement^='bottom'] {
    margin-top: 4px !important;
}
.tool_tip2 {
    width: 174px;
}
.tool_tip3 {
    width: 110px;
}

.compare_box li {
    text-align: center;
    margin-top: 4px;
    color: #022955;
    line-height: 42px;
    font-size: 16px;
}
.compare_box li:hover {
    background: rgba(18, 144, 201, 0.1);
    color: #1290c9;
    cursor: pointer;
}
.compare_box li.link_disable:hover {
    background: #e7eaed;
    color: #8497ab;
    cursor: no-drop;
}
</style>
<style>
.pop_link {
    padding: 0 0 8px;
    border-radius: 8px;
    line-height: 42px;
    font-size: 16px;
    box-shadow: 0px -8px 20px 0px rgba(10, 33, 57, 0.24);
}

@media (max-width: 821px) {
    .computer_right_cont_box {
        display: none;
    }
    .line_1 {
        white-space: nowrap !important;
    }

    .zhankai {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: #1290c9;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .zhankai img {
        height: 15px;
        width: 15px;
    }
    .phoneappl_filters .left_menu {
        display: block !important;
    }
    .coun_card li {
        width: 98% !important;
        margin: auto !important;
        margin-bottom: 10px !important;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #bfbfbf;
        border-radius: 0px !important;
        height: 45px !important;
    }
    .coun_card li:nth-child(5n) {
        margin-right: 0;
    }
    .coun_card_score {
        margin-top: 0px !important;
    }
    .coun_card_score span + span {
        margin-left: 10px !important;
    }
    .title {
        font-size: 16px !important;
        margin-top: 0px !important;
    }
    .profile .item {
        flex-wrap: wrap;
        font-size: 14px !important;
        color: #022955 !important;
        padding: 10px 0px 0px !important;
        align-items: center;
    }
    .coun_card_tit img {
        display: none;
    }
    .profile .item .txt {
        margin-left: 0px !important;
        line-height: 30px !important;
        font-size: 14px !important;
    }
    .profile .item .num {
        line-height: 30px !important;
    }
    .coun_card_color {
        display: none;
    }
    .c_d_title {
        width: 100%;
        height: 40px !important;
    }
    .country_box {
        margin-top: 30px !important;
    }
    .coun_card_tit {
        font-family: 'Arial Bold' !important;
        color: #022955 !important;
    }
    .profile .item .lab{
        font-size: 14px !important;
        color: #022955 !important;
    }
    .de_menu_item_a.router-link-active .title {
        background-color: #fff !important;
    }
    .c_d_title{
        font-size: 22px !important;
        margin-bottom: 10px !important;
    }
    .phone-bottmo{
        margin-bottom: 20px !important;
    }
    .c_detail_top_left{
        margin-top:10px !important;
    }
}
</style>
